<script setup lang="ts">
import ShieldCheckIcon from '@heroicons/vue/24/outline/esm/ShieldCheckIcon'

const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  show: {
    type: Boolean,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  error: {
    type: String,
    default: '',
  },
})

const emit = defineEmits<Emits>()

interface Emits {
  (eventName: 'update:show', show: boolean): void
  (eventName: 'update:modelValue', value: string): void
  (eventName: 'submit', value: string): void
}

const totpToken = ref('')

watch(totpToken, (token) => {
  emit('update:modelValue', token)
})

async function validateTotpToken() {
  const totp = totpToken.value
  if (totp.length === 6) {
    emit('update:modelValue', totp)
    emit('submit', totp)
  }
}

function closeDialog() {
  if (!props.loading) {
    emit('update:show', false)
  }
}
</script>

<template>
  <HeadlessTransitionRoot as="template" :show="show">
    <HeadlessDialog
      as="div"
      class="relative z-10"
    >
      <HeadlessTransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 backdrop-blur-[2px] bg-black bg-opacity-70 transition-opacity" />
      </HeadlessTransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
          <HeadlessTransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <HeadlessDialogPanel class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform rounded-lg shadow-xl bg-neutral-800 sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div>
                <div class="flex items-center justify-center w-12 h-12 mx-auto rounded-full bg-neutral-100">
                  <ShieldCheckIcon class="w-6 h-6 text-neutral-900" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <HeadlessDialogTitle as="h3" class="text-base font-semibold leading-6">
                    Account protetto da 2FA
                  </HeadlessDialogTitle>

                  <div class="mt-4">
                    <OtpInput v-model="totpToken" @submit="validateTotpToken" />

                    <p class="mt-2 text-sm text-gray-400">
                      Inserisci il codice 2FA
                    </p>

                    <template v-if="error">
                      <p class="mt-2 ml-1 text-sm text-red-400">
                        {{ error }}
                      </p>
                    </template>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button
                  type="button"
                  class="inline-flex items-center justify-center w-full px-3 py-2 text-sm font-semibold rounded-md bg-neutral-100 text-neutral-900 hover:bg-neutral-300 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus:outline-neutral-100 disabled:bg-neutral-100/50 disabled:cursor-not-allowed disabled:opacity-75"
                  :disabled="loading || totpToken.length !== 6"
                  @click="validateTotpToken"
                >
                  <template v-if="loading">
                    <svg
                      class="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      />
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  </template>
                  Conferma
                </button>
              </div>
              <div class="mt-5 sm:mt-6">
                <button
                  type="button"
                  class="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold bg-transparent rounded-md text-neutral-100 hover:bg-neutral-200/50 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus:outline-neutral-100 disabled:cursor-not-allowed disabled:opacity-75"
                  :disabled="loading"
                  @click="closeDialog"
                >
                  Annulla
                </button>
              </div>
            </HeadlessDialogPanel>
          </HeadlessTransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>
