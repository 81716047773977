<script setup lang="ts">
import type { PropType } from 'vue'
import { ref, watch } from 'vue'

defineProps({
  modelValue: {
    type: null as unknown as PropType<string>,
    default: '',
  },
})

const emit = defineEmits(['update:modelValue', 'submit'])

const inputRef = ref<HTMLInputElement[]>([])
const data = ref(['', '', '', '', '', ''])

watch(data, (data) => {
  emit('update:modelValue', data.join(''))
}, {
  deep: true,
})

function autoSubmit() {
  const text = data.value.join('')
  if (text.length === 6) {
    emit('update:modelValue', text)
    emit('submit')
  }
}

function handlePaste(e: ClipboardEvent) {
  const pasteData = (e.clipboardData?.getData('text') || '').replace(/\s+/g, '').split('')

  for (let index = 0; index < 6; index++) {
    const value = pasteData[index] || ''
    data.value[index] = value.match(/\d/) ? value : ''
  }

  autoSubmit()
}

function onKeyPress(e: KeyboardEvent, index: number) {
  if (e.key.match(/\d/)) {
    data.value[index] = e.key

    inputRef.value[index]?.blur()
    inputRef.value[index + 1]?.focus()

    const text = data.value.join('')
    emit('update:modelValue', text)

    if (index === 5) {
      autoSubmit()
    }
  }
  else {
    data.value[index] = ''
  }

  const text = data.value.join('')
  emit('update:modelValue', text)
}
</script>

<template>
  <div class="flex justify-around w-full">
    <template v-for="(value, index) in data" :key="index">
      <input
        ref="inputRef"
        :value="data[index]"
        type="text"
        maxlength="1"
        pattern="[0-9]{1}"
        class="w-10 h-10 text-center border rounded bg-neutral-800 border-neutral-500 focus:border-neutral-500 focus:shadow-none focus:ring-0 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-neutral-100"
        @keypress.prevent="(e) => onKeyPress(e, index)"
        @paste="handlePaste"
      >
    </template>
  </div>
</template>
