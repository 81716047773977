<script setup lang="ts">
import type { SignInPageErrorParam } from '@auth/core/types'
import { getQuery } from 'ufo'
import XMarkIcon from '@heroicons/vue/20/solid/esm/XMarkIcon'
import logo from '~/assets/img/scuola-per-ricchi.svg'

definePageMeta({
  layout: false,
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/',
  },
})

const route = useRoute()
const { signIn } = useAuth()

const signinErrors: Record<SignInPageErrorParam | 'default', string> = {
  default: 'Impossibile accedere.',
  Signin: 'Prova ad accedere con un altro account.',
  OAuthSignin: 'Prova ad accedere con un altro account.',
  OAuthCallbackError: 'Prova ad accedere con un altro account.',
  OAuthCreateAccount: 'Prova ad accedere con un altro account.',
  EmailCreateAccount: 'Prova ad accedere con un altro account.',
  Callback: 'Prova ad accedere con un altro account.',
  OAuthAccountNotLinked: 'Per confermare la tua identità, accedi con lo stesso account che hai usato inizialmente.',
  EmailSignin: 'L\'email non è stata inviata.',
  CredentialsSignin: 'Accesso non riuscito. Controlla che i dettagli che hai fornito siano corretti.',
  SessionRequired: 'Effettua l\'accesso per accedere a questa pagina.',
}

const error = ref<SignInPageErrorParam | 'default' | undefined>((typeof route.query.error === 'string' && route.query.error !== 'undefined') ? (route.query.error as (SignInPageErrorParam | 'default') || undefined) : undefined)
const errorMessage = computed(() => error.value ? (signinErrors[error.value] ?? signinErrors.default) : undefined)
const isAfterPasswordReset = computed(() => 'reset' in route.query)

const isVerifyRequest = computed(() => 'verify-request' in route.query)

const credentials = reactive({
  email: import.meta.env.DEV ? 'admin@katanga.app' : '',
  password: import.meta.env.DEV ? 'password' : '',
  totp: '',
})

if (typeof route.query.email === 'string') {
  credentials.email = route.query.email
}

const requestTotp = ref(false)
const requestTotpError = ref('')

const notify = useNotify()

const turnstileElement = ref<HTMLDivElement>()
const turnstile = useTurnstile(turnstileElement)

const formRef = ref<HTMLFormElement>()
const loading = ref(false)

async function submitForm() {
  // trigger the native form validation
  if (!formRef.value || !formRef.value.reportValidity()) {
    notify.error({
      title: 'Errore',
      text: 'Alcuni campi non sono validi!',
    })

    return
  }

  // do not submit twice
  if (loading.value) {
    return
  }

  // set the loading state
  loading.value = true

  // unset the errors
  error.value = undefined
  requestTotpError.value = ''

  try {
    const token = await turnstile.challenge()

    const response: any = await signIn('credentials', {
      // callbackUrl: '/',
      redirect: false,
      token,
      email: credentials.email,
      password: credentials.password,
      totp: credentials.totp,
    })

    const to = response?.url

    if (!to) {
      return
    }

    const query = getQuery(to)

    if (!query.error) {
      await navigateTo(to, { external: true })
      return
    }

    if (query.error === 'CredentialsSignin' && query.code === 'totp') {
      if (requestTotp.value === true) {
        requestTotpError.value = 'Codice errato, stai attento che il codice 2FA cambia regolarmente per motivi di sicurezza, quindi assicurati di inserire il codice più recente.'
      }

      requestTotp.value = true
    }
    else {
      error.value = query.error as any
    }
  }
  catch (e) {
    error.value = 'default'

    if (import.meta.env.DEV) {
      console.error('Auth error:', e)
    }
  }
  finally {
    loading.value = false
  }
}

async function signInWithGoogle() {
  if (loading.value) {
    return
  }

  loading.value = true

  try {
    await signIn('google')
  }
  catch (e) {
    error.value = 'default'

    if (import.meta.env.DEV) {
      console.error('Auth error:', e)
    }
  }
  finally {
    loading.value = false
  }
}

async function signInWithMagicLink() {
  if (loading.value) {
    return
  }

  loading.value = true

  try {
    const token = await turnstile.challenge()

    await signIn('email', {
      token,
      email: credentials.email,
    })
  }
  catch (e) {
    if (import.meta.env.DEV) {
      console.error('Auth error:', e)
    }
  }
  finally {
    loading.value = false
  }
}
</script>

<template>
  <div class="flex-col justify-center flex-1 min-h-full py-12 sm:px-6 lg:px-8">
    <div class="absolute top-4 right-4">
      <ToggleDarkButton />
    </div>

    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="p-3 mx-auto w-36 aspect-1/1 rounded-xl bg-default-blue">
        <img class="w-full h-full mx-auto pointer-events-none select-none" draggable="false" :src="logo" alt="SPR">
      </div>
      <h2 class="mt-6 text-2xl font-bold leading-9 tracking-tight text-center">
        Accedi al tuo account
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <template v-if="isAfterPasswordReset">
        <div class="px-6sm:rounded-lg sm:px-12">
          <div class="p-4 mb-4 rounded-md bg-green-50">
            <div class="flex">
              <div class="ml-3">
                <h3 class="text-sm font-medium text-green-800">
                  La tua password è stata reimpostata con successo!<br>
                  Ora puoi accedere al tuo account con la nuova password.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="isVerifyRequest">
        <div class="px-6 sm:rounded-lg sm:px-12">
          <div class="p-4 rounded-md bg-green-50">
            <div class="flex">
              <div class="ml-3">
                <h3 class="text-sm font-medium text-green-800">
                  Ti abbiamo inviato un'email con un link per accedere al tuo account.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="px-6 py-12 sm:rounded-lg sm:px-12">
        <Transition
          enter-active-class="duration-300 ease-out"
          enter-from-class="transform opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-200 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="transform opacity-0"
        >
          <div v-if="errorMessage" class="p-4 mb-4 rounded-md bg-red-50">
            <div class="flex">
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">
                  {{ errorMessage }}
                </h3>
              </div>
              <div class="pl-3 ml-auto">
                <div class="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    class="inline-flex px-2 py-1 text-red-500 rounded-md bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                    @click="error = undefined"
                  >
                    <span class="sr-only">Dismiss</span>
                    <XMarkIcon class="w-5 h-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>

        <form ref="formRef" class="space-y-6" action="/" method="POST">
          <div>
            <label for="email" class="block text-sm font-medium leading-6">Email</label>
            <div class="mt-2">
              <input
                id="email"
                v-model="credentials.email"
                :disabled="loading"
                :class="{ 'animate-pulse disabled:cursor-wait': loading }"
                name="email"
                type="email"
                autocomplete="email"
                required
                class="block w-full px-3 py-2 border-0 rounded-md outline-none bg-neutral-200 dark:bg-neutral-700 ring-1 ring-inset ring-transparent focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6"
              >
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium leading-6">Password</label>
            <div class="mt-2">
              <input
                id="password"
                v-model="credentials.password"
                :disabled="loading"
                :class="{ 'animate-pulse disabled:cursor-wait': loading }"
                name="password"
                type="password"
                autocomplete="current-password"
                required
                class="block w-full px-3 py-2 border-0 rounded-md outline-none bg-neutral-200 dark:bg-neutral-700 ring-1 ring-inset ring-transparent focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6"
              >
            </div>
          </div>

          <div class="flex items-center justify-end">
            <!-- <div class="text-sm leading-6">
              <NuxtLink to="/signup" class="p-1.5 rounded-md font-semibold text-blue-600 hover:text-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                Registrati
              </NuxtLink>
            </div> -->

            <div class="text-sm leading-6">
              <NuxtLink
                to="/forgot-password"
                class="p-1.5 rounded-md font-semibold text-blue-600 hover:text-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Password dimenticata?
              </NuxtLink>
            </div>
          </div>

          <div ref="turnstileElement" />

          <div>
            <button
              :disabled="loading"
              :class="{ 'animate-pulse disabled:cursor-wait': loading }"
              type="button"
              class="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              @click="submitForm"
            >
              <template v-if="loading">
                <svg
                  class="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  />
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              </template>
              Log in
            </button>
          </div>
        </form>

        <div>
          <div class="relative mt-10 select-none">
            <div class="mb-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-gray-300 dark:before:border-neutral-600 after:mt-0.5 after:flex-1 after:border-t after:border-gray-300 dark:after:border-neutral-600">
              <span class="mx-4 mb-0 text-sm text-center">Oppure</span>
            </div>
          </div>

          <button
            :disabled="loading"
            :class="{ 'animate-pulse disabled:cursor-wait': loading }"
            type="button"
            class="flex w-full justify-center rounded-md px-3 mb-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm bg-neutral-100 hover:bg-neutral-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            @click="signInWithMagicLink"
          >
            <template v-if="loading">
              <svg
                class="w-5 h-5 mr-3 -ml-1 text-current animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                />
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            </template>
            Magic Link
          </button>

          <!-- <div class="grid grid-cols-2 gap-4 mt-6"> -->
          <button
            :disabled="loading"
            :class="{ 'animate-pulse disabled:cursor-wait': loading }"
            type="button"
            class="flex w-full items-center justify-center gap-3 rounded-md bg-[#ea4335] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ea4335]"
            @click="signInWithGoogle"
          >
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 16 16">
              <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
            </svg>
            <span class="text-sm font-semibold leading-6">Google</span>
          </button>
          <!-- <button
              :disabled="loading"
              :class="{ 'animate-pulse disabled:cursor-wait': loading }"
              type="button"
              class="flex w-full items-center justify-center gap-3 rounded-md bg-[#3b5998] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3b5998]"
              @click="() => signInWithProvider('facebook')"
            >
              <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
              </svg>
              <span class="text-sm font-semibold leading-6">Facebook</span>
            </button> -->
          <!-- </div> -->
        </div>

        <p class="mt-6 text-center text-sm opacity-60">
          Leggi
          <NuxtLink
            to="/policy"
            class="underline"
          >
            termini e condizioni
          </NuxtLink>
        </p>
      </div>
    </div>

    <OtpDialog v-model:show="requestTotp" v-model="credentials.totp" :loading="loading" :error="requestTotpError" @submit="() => submitForm()" />
  </div>
</template>
